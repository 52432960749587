import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { mapState, mapGetters } from "vuex";
import { DcFeaturesApi, SysAccountApi } from "@/api";
export default {
  name: "dcFeaturesForm",
  components: {},
  props: {
    editId: [String, Number],
    action: {
      type: String,
      default: function _default() {
        return "add";
      }
    },
    source: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      form: this.$form.createForm(this),
      loaded: false,
      // 仅针对edit类型使用
      spinning: false,
      loading: false,
      fetching: false,
      // 角色列表
      roles: [],
      item: _objectSpread({}, this.source)
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters("dcFeatures", ["formatScenesList", "scenesListObj"])), mapState("dcFeatures", ["contentidtypeList"])), {}, {
    isDisabled: function isDisabled() {
      return this.action == "view";
    }
  }),
  created: function created() {
    this.getRoles();
  },
  methods: {
    getRoles: function getRoles() {
      var _this = this;
      var params = {
        page: 1,
        size: 999
      };
      this.spinning = true;
      this.roles = [];
      SysAccountApi.findpage(params).then(function (res) {
        _this.spinning = false;
        _this.roles = (res === null || res === void 0 ? void 0 : res.list) || [];
      }).catch(function () {
        // this.spinning = false;
      });
    },
    // 下拉框筛选
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this2 = this;
      if (this.loading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.loading = true;
          var params = _objectSpread({}, values);
          var method = "add";
          if (_this2.editId) {
            method = "update";
            params.id = _this2.editId;
          }
          _this2.roles.some(function (item) {
            if (item.id == params.relationroleid) {
              params.relationrolename = item.name;
              return true;
            }
          });
          params.scenename = _this2.scenesListObj[params.sceneid];
          DcFeaturesApi[method](_objectSpread({}, params)).then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.loading = false;
            // this.toast("操作失败," + err, "error");
          });
        }
      });
    }
  }
};