import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mapState, mapActions, mapGetters } from "vuex";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys, columns } from "./config/index";
import { DcFeaturesApi, SysAccountApi } from "@/api";
import Form from "./components/form.vue";
import cloneDeep from "lodash/cloneDeep";
import { BATCH_PASS, BATCH_UNPASS, ADD } from "@/utils/buttons";
export default {
  name: "dcFeatures",
  components: {
    Form: Form
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      columns: columns,
      searchKeys: searchKeys,
      roles: [],
      // 角色列表

      tipsDesc: "",
      isConfirmVisible: false,
      subLoading: false,
      action: "add",
      // 操作:add,edit,delete
      editId: undefined,
      modalVisible: false,
      source: {},
      // 表单编辑需要的字段

      defaultIndex: 0,
      types: [{
        id: 1,
        name: "生效"
      }, {
        id: 2,
        name: "失效"
      }]
    };
  },
  mixins: [Mixin, TableMixin],
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState("dcFeatures", ["contentidtypeList"])), mapGetters("dcFeatures", ["formatScenesList", "contentidtypeListObj"])), {}, {
    getDataWithKey: function getDataWithKey() {
      var _this = this;
      return function (key) {
        switch (key) {
          case "sceneid":
            return _this.formatScenesList;
          case "contentidtype":
            return _this.contentidtypeList;
          case "relationroleid":
            return _this.roles;
        }
      };
    },
    modalTitle: function modalTitle() {
      return this.action == "pass" ? "批量通过" : this.action == "unpass" ? "批量驳回" : this.action == "add" ? "新建特征组" : this.action == 'edit' ? "编辑特征组" : '';
    },
    status: function status() {
      return this.types[this.defaultIndex].id;
    },
    statusText: function statusText() {
      return this.types[this.defaultIndex].name;
    }
  }),
  created: function created() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
    this.getList();
    this.getScenesList();
    this.getContentidtypeList();
    this.getRoles();
  },
  activated: function activated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
  },
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: _objectSpread(_objectSpread({}, mapActions('dcFeatures', ['getScenesList', 'getContentidtypeList'])), {}, {
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    // 确定启用/禁用
    handleDelSubmit: function handleDelSubmit() {
      var _this2 = this;
      this.subLoading = true;
      DcFeaturesApi.updatestatus({
        featureids: this.editId,
        status: this.status == 1 ? 2 : 1
      }).then(function () {
        _this2.toast("操作成功", "success");
        _this2.isConfirmVisible = false;
        _this2.subLoading = false;
        _this2.getList();
      }).catch(function () {
        _this2.isConfirmVisible = false;
        _this2.subLoading = false;
      });
    },
    getList: function getList() {
      var _this3 = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this3.selectedRowKeys = [];
          var formValues = cloneDeep(values);
          if (formValues.date && formValues.date.length > 0) {
            formValues.starttime = formValues.date[0].valueOf();
            formValues.endtime = formValues.date[1].valueOf();
          }
          delete formValues.date;

          // 多行
          var ids = (formValues.ids || '').trim();
          if (ids) {
            var lines = ids.split(/[(\r\n)\r\n]+/);
            lines = lines.map(function (item) {
              return item.trim();
            });
            lines = lines.filter(function (item) {
              return item.trim() ? true : false;
            });
            if (lines.length > 0) formValues.ids = lines;else delete formValues.ids;
          } else delete formValues.ids;

          // 排序
          if (_this3.sortOrder) {
            formValues.orderfield = _this3.sortField;
            formValues.ordertype = _this3.sortOrder == 'ascend' ? 1 : 2;
          }
          _this3.loading = true;
          var params = _objectSpread({
            page: _this3.page,
            size: _this3.size,
            status: _this3.status
          }, formValues);
          DcFeaturesApi.list(_objectSpread({}, params)).then(function (res) {
            _this3.loading = false;
            _this3.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this3.total = (res === null || res === void 0 ? void 0 : res.total) || _this3.total;
          }).catch(function () {
            _this3.dataSource = [];
          }).finally(function () {
            setTimeout(function () {
              _this3.loading = false;
            }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    getRoles: function getRoles() {
      var _this4 = this;
      var params = {
        page: 1,
        size: 999
      };
      this.roles = [];
      SysAccountApi.findpage(params).then(function (res) {
        _this4.roles = (res === null || res === void 0 ? void 0 : res.list) || [];
        _this4.roles.forEach(function (item) {
          item.name += "[".concat(item.status == 0 ? '正常' : '停用', "]");
        });
      }).catch(function () {
        // this.spinning = false;
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    defineTipsDesc: function defineTipsDesc(action) {
      if (action == BATCH_PASS) {
        return '是否<span class="color-red">确认启用</span>所选特征组，点击确认后将执行变更其状态为“生效”';
      } else if (action == BATCH_UNPASS) {
        return '是否<span class="color-red">确认禁用</span>所选特征组，点击确认后将执行变更其状态为“失效”';
      }
      return "";
    },
    handleAction: function handleAction(action, record) {
      this.action = action;
      this.editId = (record === null || record === void 0 ? void 0 : record.id) || "";
      if (action == "deactive") {
        this.tipsDesc = this.defineTipsDesc(BATCH_UNPASS);
        this.editId = [record === null || record === void 0 ? void 0 : record.id];
        this.isConfirmVisible = true;
      } else if (action == "active") {
        this.tipsDesc = this.defineTipsDesc(BATCH_PASS);
        this.editId = [record === null || record === void 0 ? void 0 : record.id];
        this.isConfirmVisible = true;
      } else if (action == "edit") {
        this.modalVisible = true;
        this.source = record;
      } else if (action == ADD) {
        this.modalVisible = true;
        this.source = {};
      } else {
        return;
      }
    },
    tabClick: function tabClick(e, index) {
      // this.createForm.resetFields();
      this.defaultIndex = index;
      window.GLOBAL.vbus.$emit("pageBtnDisabled", [this.status == 1 ? BATCH_PASS : BATCH_UNPASS]);
      this.page = 1;
      this.size = 20;
      this.handleSearch();
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(action, ADD)
      if (action == BATCH_PASS) {
        this.tipsDesc = this.defineTipsDesc(BATCH_PASS);
      } else if (action == BATCH_UNPASS) {
        this.tipsDesc = this.defineTipsDesc(BATCH_UNPASS);
      } else if (action == ADD) {
        this.handleAction(action, {});
        return;
      }
      var ids = this.getAllCheckedKey("id");
      if (ids.length == 0) {
        this.toast("请先选择特征组", "error");
        return;
      }
      this.editId = ids;
      this.isConfirmVisible = true;
    } /*******表格复选框end********/
  })
};