var searchKeys = [{
  key: "sceneid",
  label: "业务场景",
  placeholder: "请选择业务场景",
  required: false,
  rules: [],
  select: true
}, {
  key: "ids",
  label: "特征组ID",
  placeholder: "特征组ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "name",
  label: "关键词",
  placeholder: "特征组关键词查询",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "relationroleid",
  label: "关联角色",
  placeholder: "请选择关联角色",
  required: false,
  rules: [],
  select: true
}, {
  key: "contentidtype",
  label: "内容ID类型",
  placeholder: "请选择内容ID类型",
  required: false,
  rules: [],
  select: true
}, {
  key: "date",
  label: "更新时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchKeys2 = [{
  key: "contentids",
  label: "内容ID",
  placeholder: "内容ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "desc",
  label: "关键词",
  placeholder: "内容ID描述关键词",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "date",
  label: "更新时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, searchKeys2 };