import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 105px"
      },
      form: _vm.form,
      layout: "vertical"
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "特征组名称:"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入特征组名称.",
          whitespace: true
        }, {
          max: 16,
          message: "最多16个字符"
        }],
        initialValue: _vm.item.name
      }],
      expression: "[\n            'name',\n            {\n              rules: [{\n                required: true,\n                message: '请输入特征组名称.',\n                whitespace: true,\n              }, {\n                max: 16,\n                message: '最多16个字符'\n              }],\n              initialValue: item.name,\n            },\n          ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      disabled: _vm.isDisabled,
      "max-length": 16
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "业务场景:",
      rules: {
        required: true,
        message: "请选择业务场景"
      }
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sceneid", {
        rules: [{
          required: true,
          message: "请选择业务场景"
        }],
        initialValue: _vm.item.sceneid
      }],
      expression: "[\n            'sceneid',\n            {\n              rules: [{ required: true, message: '请选择业务场景' }],\n              initialValue: item.sceneid,\n            },\n          ]"
    }],
    attrs: {
      size: "large",
      allowClear: false,
      placeholder: "Select",
      disabled: _vm.isDisabled,
      defaultActiveFirstOption: false
    }
  }, _vm._l(_vm.formatScenesList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        title: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "内容ID类型:"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contentidtype", {
        rules: [{
          required: true,
          message: "请选择内容ID类型"
        }],
        initialValue: _vm.item.contentidtype
      }],
      expression: "[\n            'contentidtype',\n            {\n              rules: [{ required: true, message: '请选择内容ID类型' }],\n              initialValue: item.contentidtype,\n            },\n          ]"
    }],
    attrs: {
      size: "large",
      allowClear: false,
      placeholder: "Select",
      disabled: _vm.isDisabled,
      defaultActiveFirstOption: false
    }
  }, _vm._l(_vm.contentidtypeList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        title: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "关联角色:"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["relationroleid", {
        rules: [{
          required: true,
          message: "请选择关联角色"
        }],
        initialValue: _vm.item.relationroleid
      }],
      expression: "[\n            'relationroleid',\n            {\n              rules: [{ required: true, message: '请选择关联角色' }],\n              initialValue: item.relationroleid,\n            },\n          ]"
    }],
    attrs: {
      "show-search": "",
      size: "large",
      disabled: _vm.isDisabled,
      placeholder: "请选择关联角色",
      "filter-option": _vm.filterOption
    }
  }, _vm._l(_vm.roles, function (d) {
    return _c("a-select-option", {
      key: d.id,
      attrs: {
        value: d.id
      }
    }, [_vm._v(" " + _vm._s(d.name) + "["), _c("span", {
      class: d.status == 0 ? "color-green" : "color-red"
    }, [_vm._v(_vm._s(d.status == 0 ? "正常" : "停用"))]), _vm._v("] ")]);
  }), 1)], 1)], 1), _c("div", {
    staticClass: "actions"
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };