var columns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '13%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '业务场景',
  dataIndex: 'scenename',
  key: 'scenename',
  width: '13%',
  scopedSlots: {
    customRender: 'scenename'
  }
}, {
  title: '特征组名称',
  dataIndex: 'name',
  key: 'name',
  width: '15%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '内容ID类型',
  dataIndex: 'contentidtype',
  key: 'contentidtype',
  width: '15%',
  scopedSlots: {
    customRender: 'contentidtype'
  }
}, {
  title: '关联角色',
  dataIndex: 'relationrolename',
  key: 'relationrolename',
  width: '15%',
  scopedSlots: {
    customRender: 'relationrolename'
  }
}, {
  title: '更新时间',
  dataIndex: 'updatedtime',
  key: 'updatedtime',
  width: '15%',
  sorter: true,
  scopedSlots: {
    customRender: 'updatedtime'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '15%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '180px',
  scopedSlots: {
    customRender: 'action'
  }
}];
var columns2 = [{
  title: '内容ID',
  dataIndex: 'contentid',
  key: 'contentid',
  width: '13%',
  scopedSlots: {
    customRender: 'contentid'
  }
}, {
  title: 'ID类型',
  dataIndex: 'contentidtype',
  key: 'contentidtype',
  width: '15%',
  scopedSlots: {
    customRender: 'contentidtype'
  }
}, {
  title: '描述',
  dataIndex: 'desc',
  key: 'desc',
  width: '35%',
  scopedSlots: {
    customRender: 'desc'
  }
}, {
  title: '更新时间',
  dataIndex: 'updatedtime',
  key: 'updatedtime',
  width: '15%',
  sorter: true,
  scopedSlots: {
    customRender: 'updatedtime'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '15%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '180px',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns, columns2 };